<template>
  <div class="ec-container">
    <div :ref="id" :style="{ width: width, height: height }"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      default: 'a',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    dayMode: {
      type: Boolean,
      default: false,
    },
    Kdata: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['newitemprice']),
  },
  watch: {
    Kdata(newValue) {
      console.log('来了来了')
      if (newValue.length) {
        this.myChart.clear()
        this.drawLine()
      }
    },
    // newitemprice() {
    //   this.drawLine()
    // },
  },
  beforeDestroy() {
    //销毁ECharts实例
    this.myChart.dispose()
    this.myChart = null
  },
  methods: {
    drawLine() {
      this.myChart.setOption(this.setKOption(this.Kdata, this))
    },
    calculateMA(dayCount) {
      var result = []
      for (var i = 0, len = this.klineData.values.length; i < len; i++) {
        if (i < dayCount) {
          result.push('-')
          continue
        }
        var sum = 0
        for (var j = 0; j < dayCount; j++) {
          sum += +this.klineData.values[i - j][1]
        }
        result.push(+(sum / dayCount).toFixed(3))
      }
      return result
    },
    calculateMA2(dayCount) {
      const result = []
      for (let i = 0, len = this.klineData.values.length; i < len; i++) {
        if (i < dayCount) {
          result.push('-')
          continue
        }
        result.push(this.newitemprice)
      }
      return result
    },
    setKOption(Kdata, that) {
      // var upColor = '#fff'
      // var upBorderColor = '#8A0000'
      // var downColor = '#00da3c'
      // var downBorderColor = '#008F28'
      var upColor = '#EB4D5C' //涨
      var upBorderColor = '#EB4D5C'
      var downColor = '#53B987'
      var downBorderColor = '#53B987'

      var data0 = splitData1()
      this.klineData = data0
      function splitData1() {
        var categoryData = []
        var values = []

        for (var i = 0; i < Kdata.length; i++) {
          let time = ''
          // 等于5才为真
          // console.log(Kdata[i])
          if (that.dayMode) {
            // time = Kdata[i].CreateTime.split(' ')[0]
            time = Kdata[i].Time.split(' ')[0]
          } else {
            // time = Kdata[i].CreateTime.split(' ')[1]
            time = Kdata[i].Time.split(' ')[1]
            time = time.substr(0, 5)
          }
          categoryData.push(time)
          let arr = [Kdata[i].Open, Kdata[i].Close, Kdata[i].Low, Kdata[i].High]
          values.push(arr)
        }
        return {
          categoryData: categoryData,
          values: values,
        }
      }
      return {
        legend: {
          data: ['MA1','MA5', 'MA10', 'MA20'],
          textStyle: {
            color: '#626263' 
          }
        },
        visualMap: {
          show: false,
          seriesIndex: 4,
          dimension: 2,
          pieces: [
            {
              value: 1,
              color: downColor
            },
            {
              value: -1,
              color: upColor
            }
          ]
        },
        grid: {
          show: false,
          left: '8%',
          right: '12%',
          bottom: '10%',
          top: '5%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        xAxis: {
          type: 'category',
          data: data0.categoryData,
          scale: true,
          boundaryGap: false,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#626263',
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['#626263'],
              width: 1,
              type: 'dashed',
            },
          },
          splitNumber: 10,
          min: 'dataMin',
          max: 'dataMax',
        },
        yAxis: {
          scale: true,
          position: 'right',
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#626263',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#626263'],
              width: 1,
              type: 'dashed',
            },
          },
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
        ],
        markLine: {
          symbol: ['none', 'none'], //去掉箭头
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'solid',
                color: {
                  //设置渐变
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'red ', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'blue', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              label: {
                show: true,
                position: 'middle',
              },
            },
          },
        },
        series: [
        {
            name: that.Kname,
            type: 'candlestick',
            data: data0.values,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: upBorderColor,
              borderColor0: downBorderColor
            },
            markPoint: {//最高最低
              symbolSize: 20,
              itemStyle: {
                normal: {
                  color: 'rgba(0,0,0,0)'
                }
              },
              label: {
                show: true,
                color: '#c5c5c5'
              },
              data: [
                {
                  name: 'highest value',
                  type: 'max',
                  valueDim: 'highest'
                },
                {
                  name: 'lowest value',
                  type: 'min',
                  valueDim: 'lowest'
                }
              ]
            },
          },
          // {
          //   name: 'MA0',
          //   type: 'line',
          //   data: this.calculateMA2(0),
          //   smooth: false,
          //   showSymbol: false,
          //   color: 'hsl(17.14deg 94.23% 59.22%)',
          //   lineStyle: {
          //     width: 1,
          //     opacity: 0.8,
          //   },
          //   itemStyle:{
          //     normal:{
          //         lineStyle:{
          //           width:1,
          //           type:'dashed'  //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
          //         }
          //     }
          //   }, 
          // },
          {
            name: 'MA1',
            type: 'line',
            data: this.calculateMA(1),
            smooth: true,
            showSymbol: false,
            color: 'yellow',
            lineStyle: {
              width: 1,
              opacity: 0.8,
            },
          },
          {
            name: 'MA5',
            type: 'line',
            data: this.calculateMA(5),
            color: '#EB4D5C',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1,
              opacity: 0.8,
            },
          },
          {
            name: 'MA10',
            type: 'line',
            data: this.calculateMA(10),
            smooth: true,
            showSymbol: false,
            color: 'green',
            lineStyle: {
              width: 1,
              opacity: 0.8,
            },
          },
          {
            name: 'MA20',
            type: 'line',
            data: this.calculateMA(20),
            smooth: true,
            showSymbol: false,
            color: 'pink',
            lineStyle: {
              width: 1,
              opacity: 0.8,
            },
          },
        ],
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.myChart = this.$echarts.init(this.$refs[this.id])
    })
  },
}
</script>

<style>
.ec-container {
  padding: 0 2px;
}
</style>
